.ui-statItem-valueText {
  color: #fde47c;
  margin-left: 5px;
}

.ui-statItem {
  text-align: center;
  padding: 16px;
  box-sizing: border-box;
}

.ui-statItem-value {
  font-size: 2em;
  color: #1695a4;
  margin-bottom: 8px;
}

.ui-statItem-value b {
  font-size: 1.5em;
}

.ui-statItem-text {
  font-weight: 400;
  margin: 0;
}

.ui-siteStats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media screen and (min-width: 480px) {
  .ui-siteStats {
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
}