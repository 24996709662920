.main-top-container {
  .header-heading {
    font-size: 2.25em;
    padding-top: 1.15em;

    @include media($largeWidth) {
      padding-top: 0;
    }
  }

  .header-descr {
    p {
      font-size: 1.25em;
    }
  }

  .btn-block {
    padding-bottom: 4rem;
    max-width: 70%;

    @include media($largeWidth) {
      max-width: 100%;
    }
  }
}

.main-header + div,
.section-order-banner {
  padding-top: 85px;
}

.main-header {
  background-color: transparent;

  @include media($mediumWidth) {
    text-align: center;
  }

  @include media($smallWidth) {
    #id_current_user_auth_subcontainer {
      display: inline-block;
      margin-left: 0;
    }

    .top-block-container #id_esauth_myaccount_login_link {
      padding: 11px 10px;
      margin: 0;
    }
  }
}

.top-block-container {
  background-color: transparent;
  transition: background-color .2s;

  &.scroll {

    @extend .bg-color;
    @extend .light-shadow;
  }
}

.main-menu {
  margin: 0;
  display: inline-block;

  @include media($largeWidth) {
    padding: .5rem 1rem;
    position: absolute;
    left: 0;
    top: 100%;
    background-color: #eaad6c;
    margin: 0;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
    transition: transform .2s;

    &.active {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }

  @include media(840px) {
    display: inline-block !important;
    height: 100%;
    top: 0;
    overflow: auto;
    position: fixed;
    padding: 0;

    &.active {
      display: inline-block !important;
      margin-top: 85px !important;
      padding-bottom: 85px !important;
    }
  }

  @include media($smallWidth) {
    &.active {
      margin-top: 72px !important;
    }
  }
}

.menu-item:not(.menu-item-type-post_type) {

  @include media($largeWidth) {
    padding: 1rem;
    margin: 0;
  }

  @include media(1024px) {
    & .menu-link:hover {
      color: $white;
    }
  }

  @include media(840px) {
    width: 100%;

    &:not(:first-child) {
      .menu-link {
        border-top: 1px solid rgba(255, 255, 255, .5);
      }
    }

    .menu-link {
      width: 100%;
      display: inline-block;
      padding: .85rem 1rem;
    }
  }
}

.menu-link {
  transition: color .2s;
  padding: .5rem 0;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    left: 100%;
    bottom: 0;
    background-color: $red-light;
    height: 3px;
    transition-property: left;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &:hover {
    color: rgba(255, 255, 255, .8);

    &:after {
      left: 0;
    }
  }

  @include media(420px) {
    text-align: left;
  }
}

span.menu-link {
  &:after {
    display: none;
  }
}

.dropmenu {
  position: relative;
}

.dropmenu > .menu-link {
  position: relative;
}

.dropmenu > .menu-link:before {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: $white transparent transparent;
  z-index: 1;

  @include media(840px) {
    bottom: -2px;
  }
}

.dropmenu:hover > .menu-link:before {
  opacity: 1;
  border-top-color: $white;
}

.dropmenu:hover > .menu-link {
  color: $white;
}

.dropmenu ul {
  position: absolute;
  top: 100%;
  list-style: none;
  left: 0;
  padding: 10px 0;
  margin: 0;
  background-color: $red-light;
  border-radius: 0 0 2px 2px;
  z-index: 1;
  transform: translateY(-30px);
  opacity: 0;
  visibility: hidden;
  transition: transform .5s, opacity .4s;
}

.dropmenu:hover > ul {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropmenu > ul > li {
  margin: 0;
}

.dropmenu a {
  text-decoration: none;
  color: $white;
  padding: 8px 15px;
  font-size: 14px;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  box-sizing: border-box;
  text-align: left;
  transition: background-color .2s, color .2s;

  @include media($mediumWidth) {
    text-align: center;
  }

  @include media(420px) {
    text-align: left;
  }
}

.dropmenu a:hover {
  color: rgba(255, 255, 255, .8);
}

@include media(840px) {
  .dropmenu ul,
  .dropmenu:hover ul {
    position: relative;
    width: 100%;
    transform: none;
    opacity: 1;
    visibility: visible;
    display: none;
    box-shadow: none;
    padding: 0;
  }

  .dropmenu > .menu-link:before {
    border-top-color: $white;
    left: auto;
    right: 15px;
    top: -5px;
  }

  .dropmenu.active .menu-link:before {
    transform: rotateZ(180deg);
    top: -6px;
  }

  .dropmenu a {
    padding: 1rem 1.5rem;
  }
}

.toggle-menu.active {
  span {
    background-color: transparent;

    &:after,
    &:before {
      top: auto;
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }
  }
}