.navigation .screen-reader-text {
  display: none;
}

.navigation.pagination .nav-links {
  text-align: center;

  .prev {
    float: left;
  }

  .next {
    float: right;
  }

  .page-numbers {
    margin: 0 3px;
  }
}

.section-page-content.single-page .navigation {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
}

.nav-previous,
.nav-next {
  margin-bottom: 10px;
  display: inline-block;
  width: auto;
}

.nav-next {
  float: right;
}

.block-after-post {
  width: 100%;
  display: inline-block;
  margin: 15px 0;
}

.posts-list .entry-title {
  margin-top: 0;
  margin-bottom: 10px;
}

.posts-list .posted-on {
  margin-bottom: 20px;
  display: block;
}

.posts-list .posted-on,
.posts-list .entry-footer {
  font-size: 14px;
}

.posts-list .post .entry-header h2 {
  font-weight: 400;
}

label .screen-reader-text {
  display: none;
}

.search-field {
  width: 100%;
}

.search-form {
  text-align: center;
}

.entry-content-link {
  font-size: .875em;
  font-weight: 700;
}

button.search-submit {
  background-color: #e77169;
  -webkit-box-shadow: 0 0 0 1px #e77169;
  box-shadow: 0 0 0 1px #e77169;
  padding: 6px 10px;
  font-weight: 400;
  -webkit-transition: all .2s;
  transition: all .2s;
  height: 30px;
  border-radius: 2px;
  margin-top: 15px;
  color: #fff;
  vertical-align: middle;
  display: inline-block;
}

.search-submit .screen-reader-text {
  line-height: 1;
}

blockquote {
  position: relative;
  padding-left: 1.5rem;
  margin: 0;
  display: inline-block;
}

blockquote:before,
q:after {
  content: " ";
  width: 4px;
  height: calc(100% - .75rem);
  background-color: #02ca02;
  position: absolute;
  top: 0;
  margin: auto;
  border-radius: 0 4px 4px 0;
  left: 0;
}

blockquote cite:before,
blockquote small:before {
  content: "\2014\00a0";
}

.comment-navigation .nav-previous:not(:empty) + .nav-next:not(:empty):before,
.entry-footer > span:not(:last-child):after,
.image-navigation .nav-previous:not(:empty) + .nav-next:not(:empty):before {
  content: "\002f";
  display: inline-block;
  opacity: .7;
  padding: 0 .53846em;
}

.bypostauthor > article .fn:after {
  content: "\f304";
  left: 3px;
  position: relative;
  top: 5px;
}

.comment-metadata .edit-link:before,
.pingback .edit-link:before {
  content: "\002f";
  display: inline-block;
  opacity: .7;
  padding: 0 .53846em;
}

.posts-list .post {
  margin-bottom: 2rem;
  padding-bottom: 1.85rem;
  border-bottom: 1px solid #ddd;
}

.entry-footer,
.posted-on {
  margin-top: 15px;
  font-size: .875em;
}

.single-page .posted-on {
  margin: 0 0 30px;
  width: 100%;
  display: inline-block;
}

.single-page h1.entry-title {
  margin: 0 0 15px;
}