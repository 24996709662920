@import "normalize";

/* stylelint-disable */

/* latin */

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"), url("https://fonts.gstatic.com/s/opensans/v14/DXI1ORHCpsQm3Vp6mXoaTegdm0LZdjqr5-oayXSOefg.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("https://fonts.gstatic.com/s/opensans/v14/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url("https://fonts.gstatic.com/s/opensans/v14/xjAJXh38I15wypJXxuGMBo4P5ICox8Kq3LLUNMylGO4.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url("https://fonts.gstatic.com/s/opensans/v14/MTP_ySUJH_bn48VBG8sNSugdm0LZdjqr5-oayXSOefg.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("https://fonts.gstatic.com/s/opensans/v14/k3k702ZOKiLJc3WVjuplzOgdm0LZdjqr5-oayXSOefg.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* stylelint-enable */

*,
*:after,
*:before {
  box-sizing: border-box;
}

*:active,
*:focus,
*:hover {
  outline: none;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

ul,
ol {
  li {
    margin-bottom: .75rem;
  }
}

button,
input[type="submit"] {
  cursor: pointer;
}

button {
  border: none;
}

input:not([type="submit"]),
select,
textarea,
button,
body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.2;
  background-color: $white;
}

input:not([type="submit"]),
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select::-ms-expand {
  display: none;
}

.container {
  margin: 0 auto;
  max-width: calc(1140px + 2rem);
  width: 100%;
  padding: 2rem 1rem;
  vertical-align: top;
}

.list-clear {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-columns {
  li {
    max-width: calc(33.33333% - 1rem);

    @include media($smallWidth) {
      max-width: calc(50% - 1rem);
    }
  }
}

.list-style-check,
.list-style-circle,
.list-style-number {
  list-style-type: none;

  li {
    display: inline-block;
    width: 100%;
    position: relative;
    vertical-align: top;
  }
}

.list-style-check {
  li {
    padding-left: 3rem;

    &:before {
      content: "\2714";
      color: $green;
      font-size: 1.15em;
      position: absolute;
      left: 1.5rem;
    }

    @include media($mediumWidth) {
      padding-left: 2rem;

      &:before {
        left: .5rem;
      }
    }
  }
}

.list-style-number {
  counter-reset: list;

  li {
    padding-left: 1rem;

    &:before {
      content: counter(list) ". ";
      counter-increment: list;
      color: #677b8f;
      font-size: 1em;
      position: absolute;
      left: 0;
      top: 3px;
      font-weight: 700;
    }
  }
}


.list-style-circle {
  li {
    padding-left: 3rem;

    &:before {
      content: "";
      background-color: $green;
      position: absolute;
      border-radius: 50%;
      height: 6px;
      width: 6px;
      top: 8px;
      left: 1.5rem;
    }
  }

  &.no-padding {
    li {
      padding-left: 1rem;

      &:before {
        left: 0;
      }
    }
  }
}

.hide {
  display: none;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0 0 1.5rem;
}

.icon-before {
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  content: " ";
}

dl,
dt,
dd {
  margin: 0;
}

/*

  FLEX

*/
.flex {
  display: inline-flex;
  display: flex;
  flex-shrink: 1;
}

.flex-start {
  justify-content: flex-start;
}

.space-between {
  justify-content: space-between;
}

.flex-and {
  justify-content: flex-end;
}

.flex-h-center {
  justify-content: center;
}

.flex-v-center {
  align-items: center;
}

.flex-v-stretch {
  align-items: stretch;
}

.flex-wrap {
  flex-wrap: wrap;
}

/*

  end FLEX

*/

.toggle-menu {
  display: none;
}

.bg-light {
  background-color: $grey-light;
}

.bg-gradient {
  background: $red-dark;
  background: linear-gradient(45deg, $orange 0%, $red-dark 50%, $orange 100%);
}

.bg-orange {
  background-color: $orange;
}

.btn {
  border-radius: 2px;
  padding: 1rem 2.5rem;
  font-size: 1rem;
  font-weight: 700;
  transition: all .2s;
}

.btn-red-light {
  background-color: $red-light;
  color: $white;
}

.btn-red-shadow {
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, .36);

  &:hover {
    box-shadow: 0 7px 7px 0 rgba(0, 0, 0, .36);
  }
}

.btn-black-shadow {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .4);

  &:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
  }
}

.btn-red-light {
  background-color: $red-light;
  color: $white;

  &:hover {
    background-color: #004fb1;
  }
}


.main-wrapper {
  overflow: hidden;
}

.main-header {
  background: $red-light;
  background: linear-gradient(45deg, $red-light 0%, $red-dark 100%);
  color: $white;
  background-size: cover;
  background-position: center top;
}

.top-block-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;

  > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    img {
      vertical-align: middle;
    }
  }

  .main-menu {
    display: flex;
    align-items: center;
    list-style: none;

    .menu-item {
      padding: 0 .5rem;
      margin-bottom: 0;
      display: inline-block;

      .menu-link {
        text-decoration: none;
        color: $white;
        display: inline-block;
        font-size: .875em;
      }
    }
  }

  #current_auth_container {
    vertical-align: middle;
    width: 110px;
  }

  #id_esauth_myaccount_login_link {
    color: $white;
    box-shadow: 0 0 0 1px $white;
    padding: 6px 10px;
    background-color: transparent;
    border-radius: 2px;
    cursor: pointer;
    transition: all .2s;
    margin-left: .75rem;
    white-space: nowrap;
    font-size: .875em;

    &:hover {
      box-shadow: 0 0 0 1px $white;
      color: $white;
    }
  }

  .logo {
    .logo-text {
      flex: 0 0 115px;
      padding: 0 0 0 10px;
      font-size: .8125rem;
      margin-left: 10px;
      border-left: 1px solid $white;
      color: $white;
      display: inline-block;
      vertical-align: middle;

      @include media(520px) {
        display: none;
      }
    }

    img {
      max-width: 232px;

      @include media($smallWidth) {
        max-width: 100px;
      }
    }
  }
}


@media (max-width: 1160px) {
  .toggle-menu {
    width: 35px;
    height: 40px;
    margin-left: 1.5rem;
    position: relative;
    background-color: transparent;
    display: inline-block;

    span {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 100%;
      background-color: $white;
      height: 2px;

      &:after,
      &:before {
        transition: transform .2s;
        content: " ";
        width: 100%;
        height: 2px;
        background-color: $white;
        top: -11px;
        position: absolute;
        left: 0;
      }

      &:after {
        top: 11px;
      }
    }
  }
}

@media (max-width: $smallWidth) {
  .toggle-menu {
    margin-left: 5px;
  }
}

.bg-light {
  background-color: $grey-light;
}

.bg-color {
  background: $red-light;
  background: linear-gradient(45deg, $red-light 0%, $red-dark 100%);
}

.color-white {
  color: $white;
}

.color-title {
  color: $dark !important;
}

.color-seo-title {
  color: $sidebar-title-color;
}

.link-color {
  color: $blue !important;
}

.font-large {
  font-size: 1.15em;

  strong {
    font-weight: 600;
  }
}

.font-small {
  font-size: 0.875em;

  strong {
    font-weight: 600;
  }
}

input,
select {
  border: 1px solid #d6d6d6;
  padding: .6875rem 1rem;
  font-style: italic;
  color: #303030;
  transition: all .2s;
  box-sizing: border-box;

  &.error {
    border-color: #ff9b9b;
  }

  &:focus {
    box-shadow: 0 0 0 1px $blue-light inset;
    border-color: rgba(214, 214, 214, .3);
  }
}

select {
  padding: .6875rem 1rem;
}

ul,
ol {
  padding: 0 0 0 30px;

  ul,
  ol {
    margin: 1em 0;
  }
}

ul.clear-list {
  padding-left: 20px;
  list-style: none;
}

ul.item-list {
  padding-left: 20px;
  list-style: none;
}

a {
  color: $blue;
}

.ui-block,
.widget,
.kc__wrap {
  background-color: $grey-light;
  border: 1px solid $grey-light;
  position: relative;
  padding: 1rem;
  margin-bottom: 2rem;

  &.no-bg {
    background-color: transparent;
    box-shadow: none;
  }

  &.no-padding {
    padding: 0;
  }
}

.seo-ui-block {
  background-color: $grey-light;
  border: 1px solid $grey-light;
  position: relative;
  padding: 1.5rem 2rem 1rem 3rem;
  font-size: .875rem;
  display: inline-block;
  width: auto;
}

.seo-title-example {
  padding-left: 1.1rem;
}

.seo-example {
  color: #39464c;
  font-size: .875em;

  @extend .seo-title-example;
}

.left-ribbon {
  content: " ";
  width: 4px;
  height: 100%;
  background-color: $red-light;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 0 4px 4px 0;
}

.seo-padding-top {
  padding-top: .75rem;

  @extend .seo-title-example;
}

.breadcrumbs {
  padding-top: 85px;
  font-size: .875em;

  .breadcrumbs-list {
    position: relative;
    top: 15px;
  }

  .container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .bc-nav-item {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    color: #aaa;

    &:not(:last-child):after {
      content: ">";
      display: inline-block;
      padding: 0 5px;
      font-size: 14px;
      color: #aaa;
    }
  }
}

.text-center {
  text-align: center;
}

.content-heading {
  font-size: 1.625em;
  font-weight: 600;
}

.icon-before {
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  content: " ";
}

.btn-block {
  padding-top: 12px;
}

.btn {
  border-radius: 2px;
  display: inline-block;
  padding: 1rem 2.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  transition: all .2s;
}

.btn-red-light {
  background-color: $red;
  color: $white;

  &:hover {
    background-color: #c23637;
  }
}

.btn-red-shadow {
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, .36);

  &:hover {
    box-shadow: 0 7px 7px 0 rgba(0, 0, 0, .36);
  }
}

.light-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .15);
}

.btn-white {
  background-color: $white;
  color: $blue;
  box-shadow: 0 10px 15px 0 rgba(255, 255, 255, .3);

  &:hover {
    box-shadow: none;
  }
}


button[type="button"]:not(.slick-arrow):not(.Zebra_DatePicker_Icon) {

  @extend .btn;
  @extend .btn-red-light;
}

input[type="checkbox"] {
  width: 17px;
  height: 17px;
  vertical-align: text-bottom;
  margin-right: 10px;

  &:checked {
    background-color: $red-light;
    border-color: $red-light;
  }
}

table:not(.solution-table):not([class^="dp_"]) {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  margin: 1rem 0;

  td,
  th {
    padding: .5rem 1rem;
    text-align: left;
    vertical-align: top;
  }

  th {
    font-weight: 700;
    border-bottom: 1px solid $red-light;
  }

  td {
    border-top: 1px solid #ddd;
    transition: background-color .2s;
  }

  td:not(:first-child),
  th:not(:first-child) {
    border-left: 1px solid #ddd;
  }
}