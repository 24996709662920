@media (max-width: 1160px) {
  .navigation {
    margin-left: auto;
    margin-right: 0;
  }
}

@media (max-width: $smallWidth) {
  .pages .section {
    .content {
      box-shadow: none;
      border-radius: 0;
      margin-bottom: 0;
      max-width: 100%;
    }

    &.section-footer {
      .container {
        padding: 2rem 1rem;
      }
    }
  }

  body:not(.home) {
    .section-page-content > .container .content {
      margin-bottom: 0;
    }
  }

  .ui-block {
    padding: 1rem 1rem 2rem;
  }
}