[data-fixed-container] {
  position: relative;

  [data-fixed-box] {
    position: absolute;
    width: 240px;

    &.fixed {
      position: fixed;
    }

    &.fixed-bottom {
      position: absolute;
    }

    @include media(1024px) {
      &,
      &.fixed,
      &.fixed-bottom {
        position: relative;
        width: 100%;
        top: auto !important;
      }
    }
  }
}

.section-order-banner {
  > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  .order-banner-block {
    width: 100%;
    max-width: 860px;
    margin-right: auto;
    margin-left: auto;
  }

  .banner-heading {
    font-size: 2.215rem;
    font-weight: 700;

    @include media($mediumWidth) {
      font-size: 2rem;
    }

    @include media($smallWidth) {
      font-size: 1.85rem;
    }
  }

  .banner-heading-description {
    font-size: 1.375rem;
    margin-top: .75em;
    margin-bottom: .75em;

    @include media($mediumWidth) {
      font-size: 1.15rem;
    }

    @include media($smallWidth) {
      font-size: 0.95rem;
    }
  }

  .ui-get-order-custom {
    white-space: nowrap;
    padding: 1.1rem 2.2rem;
  }

  @include media(600px) {
    > .container {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }
  }
}

.section-services {
  min-height: 40vh;
}

.how-it-works-item {
  border-bottom: 1px solid #ecf0f1;
  margin-bottom: 30px;
  padding-bottom: 30px;

  .how-item-title {
    margin-top: 0;
  }

  .over-img-box {
    max-width: 251px;
  }

  .how-description {
    padding-left: 20px;
  }

  .units-row {
    margin-bottom: 0;
    overflow: hidden;

    & .unit-50 {
      width: 50%;
      float: left;
    }
  }

  .small {
    display: block;
    border: 1px solid #cdd2d5;
    width: 240px;
    font-size: 12.75px;

    img {
      height: auto;
      margin-top: 0;
      width: 100%;
      transition: all .2s ease-in-out;
    }
  }

  .hvr-grow-shadow {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    transition-duration: 0.3s;
    transition-property: box-shadow, transform;

    &:hover {
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
      transform: scale(1.05);
    }
  }

  @include media($mediumWidth) {
    padding-bottom: 15px;
    margin-bottom: 10px;

    .over-img-box {
      display: none;
    }

    .how-description {
      width: auto !important;
    }
  }
}

.pages {
  .stats-section .container {
    padding: 1.5rem 1rem 3.5rem;

    @include media(1024px) {
      padding-bottom: 2rem;
    }
  }
}

.footer-descr {
  font-weight: 300;
  font-size: 1.5625rem;
  color: $dark;
  max-width: 750px;
}

.section-page-content {
  > .container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include media(1024px) {
      flex-wrap: wrap;

      .content {
        margin: 0 0 2.3125rem;
        max-width: 100%;
      }

      .sidebar {
        max-width: 100%;
      }
    }
  }

  .content {
    width: 100%;
    max-width: 100%;
  }

  &.without-sidebar {
    .content {
      max-width: 860px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  /* stylelint-disable */
  &:not(.without-sidebar) {
    .content {
      margin: 0 1.875rem;
      max-width: calc(100% - 360px);
      order: 2;

      @include media(1024px) {
        margin-left: 0 !important;
        max-width: 100% !important;
        order: 1 !important;
      }
    }
  }

  .sidebar {
    width: 100%;
    max-width: 240px;

    a {
      font-size: 14px;
    }

    #current_sof_container {
      margin-top: 0;
    }
  }

  .sidebar.sidebar-left {
    order: 1;
  }

  .sidebar.sidebar-right {
    order: 2;
  }
}
/* stylelint-enable */
.content {
  position: relative;
  z-index: 0;
  color: $text-color;

  h1,
  h1.content-heading,
  h2.content-heading {
    color: $title-color;
    font-size: 1.75rem;
    font-weight: 600;
  }

  h2,
  h3 {
    font-weight: 600;
    margin: 1rem 0;
    color: $title-color;
  }

  h2 {
    font-size: 1.35rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  p {
    margin: 0 0 .75rem;
    line-height: 1.5;
  }

  @include media($mediumWidth) {
    h1,
    h1.content-heading,
    h2.content-heading {
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1.25rem;
    }

    h3 {
      font-size: 1.125rem;
    }
  }

  .section-order-banner,
  .section-hiw {
    padding: 2rem;
    margin: 2rem -2rem;
    background-color: $white;

    .container {
      padding: 0;
    }

    .banner-heading {
      font-size: 1.25rem;
    }
  }
}


.services-block {
  padding-left: 145px;
  position: relative;

  @include media($mediumWidth) {
    padding-left: 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 2rem;
    left: 0;

    @include media($mediumWidth) {
      position: relative !important;
      display: block;
      top: 0 !important;
    }
  }

  &:nth-of-type(1) {
    &:before {

      @extend .icon-networking;
      @extend .icon-networking-dims;
    }
  }

  &:nth-of-type(2) {
    &:before {

      @extend .icon-settings;
      @extend .icon-settings-dims;
    }
  }
}

.ribbon-list {
  padding: 0;
  margin: 1.5rem 0;
  list-style: none;

  li {
    position: relative;
    margin-bottom: 1.25rem;

    strong {
      font-weight: 700;
      font-size: 1.25rem;
      color: #3e3e3e;
      display: block;
      padding-bottom: .5rem;
    }

    &:after {

      @extend .left-ribbon;
      left: -2rem;

      @include media($smallWidth) {
        left: -1rem;
      }
    }
  }
}

.page-404 {
  .ui-block {
    padding: 4rem 2rem;
    width: 100%;
    text-align: center;

    h1 {
      font-size: 3.125rem;
      font-weight: 300;
    }

    p {
      font-weight: 400;
    }
  }
}

.contact-us-page {
  .email-button {
    text-align: center;

    a {
      display: inline-block;
      margin: 1rem 0 1.75rem;
      padding: .75rem 2rem;
      font-size: .85rem;
      font-weight: 400;
    }
  }

  .hidden-text:before {
    content: attr(data-title);
  }
}

.ribbon {
  position: relative;

  &:after {

    @include ribbon;
    left: -2rem;
  }
}

.pr-block {
  width: 100%;
  flex-wrap: nowrap;
  margin: 2rem 0;
}

.pr-block,
.pr-box {
  display: flex;
  flex: 1 1 100%;
}

.pr-box {
  flex-wrap: wrap;
  padding: 0 30px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, .15);
  margin: 3px;
  font-size: 16px;
  width: 33%;
  overflow: hidden;
  background-color: $white;

  @include media($mediumWidth) {
    font-size: 14px;
  }

  &.pr-titles {
    background-color: transparent;
    box-shadow: none;
    padding: 0 10px 0 0;
    max-width: 130px;
  }
}

.pr-titles {
  .pr-item {
    text-align: right;
    padding: 20px 15px 20px 0;
    color: #a1a1a1;
    font-size: .875em;

    strong {
      font-size: 1rem;
    }

    &:first-child,
    &:nth-of-type(2) {
      padding: 20px 15px 20px 0;
      border-bottom: 1px solid #eaeaea;
    }

    @include media($mediumWidth) {
      min-height: 60px;
    }
  }
}

.pr-item {
  display: inline-block;
  width: 100%;
  padding: 20px 0;
  text-align: center;
  border-bottom: 1px solid #eaeaea;
  color: #3f3f3f;
  min-height: 80px;

  &:first-child,
  &:last-child,
  &:nth-of-type(2) {
    border-bottom: none;
  }

  strong {
    display: block;
  }
}

.price-value {
  font-weight: 600;
  font-size: 30px;
  position: relative;

  &:after {
    content: " ";
    width: 200%;
    height: 100%;
    background-color: #f5f4f4;
    top: 0;
    position: absolute;
    z-index: 1;
    left: -50%;
    margin: 0;
  }

  span {
    position: relative;
    z-index: 2;
  }

  @include media($mediumWidth) {
    font-size: 16px;
  }
}

@include media($smallWidth) {
  .pr-block {
    flex-direction: column;
    margin-bottom: 0;
  }

  .pr-box {
    margin: 0 0 30px;
    padding: 0;
    width: 100%;
  }

  .pr-item {
    padding: 35px 0 10px;
    position: relative;
    min-height: auto;

    &:before {
      background-color: hsla(0, 5%, 96%, .48);
      color: #a7a7a7;
      font-size: 14px;
      font-weight: 400;
      left: 0;
      margin: auto;
      padding: 5px 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }

    &.price-title:before {
      content: "Title";
    }

    &.price-value:before {
      content: "Price";
    }

    &.price-type:before {
      content: "Paper type";
    }

    &.price-disc:before {
      content: "Discipline";
    }

    &.price-pages:before {
      content: "Pages";
    }

    &.price-deadline:before {
      content: "Deadline";
    }
  }

  .pr-item,
  .pr-item:first-child,
  .pr-item:nth-of-type(2) {
    border-bottom: 1px solid #eaeaea;
  }

  .pr-box.pr-titles,
  .price-value:after {
    display: none;
  }
}