.section-footer {
  background-color: $dark-grey;
  color: $white;

  ul {
    padding: 0;
    padding-right: 1rem;
    flex-basis: calc(20% - 1rem);
    margin-top: 0;

    @extend .clear-list;

    @include media($mediumWidth) {
      flex-basis: 33.33333%;
    }

    @include media($smallWidth) {
      flex-basis: 50%;
    }

    .links-block-title {
      color: $white;
      margin-bottom: 1.5rem;
      display: inline-block;
    }

    li {
      margin-bottom: .625rem;
    }

    a {
      font-size: 0.875rem;
      color: #9bbcff;

      &:hover {
        color: #bfdeff;
      }
    }

    @include media($smallWidth) {
      max-width: 100%;
      margin: 0;
      width: 100%;
    }
  }

  .info-block {
    .payment {
      text-align: right;
    }

    .follow {
      p {
        margin-right: 10px;
      }
    }

    .payment,
    .follow {
      padding-right: 1rem;
      padding-left: 1rem;
      flex-basis: 50%;
      max-width: 50%;

      @include media($smallWidth) {
        flex-basis: 100%;
        max-width: 100%;
      }

      p {
        font-weight: 600;
        margin-bottom: 1rem;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .follow-imgs {
      display: inline-block;
      vertical-align: middle;

      a {
        display: inline-block;
      }
    }
  }

  .payment-imgs {
    display: inline-block;
    vertical-align: middle;
  }

  .copyright {
    flex: 0 0 100%;
  }

  @include media($smallWidth) {
    .links-block {
      flex-wrap: wrap;
    }

    .payment {
      width: 100%;
    }

    .copyright,
    .info-block .payment,
    .info-block .follow {
      text-align: center;
    }
  }
}