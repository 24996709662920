/*   Responsive */

@mixin media($media) {

  @if ( type-of($media) != string ) {

    @media only screen and (max-width: $media) {

      @content;
    }
  }

  @else {

    @if $media == small {

      @media only screen and (max-width: $smallWidth) {

        @content;
      }
    }

    @else if $media == extralarge {

      @media only screen and (max-width: $extralargeWidth ) {

        @content;
      }
    }

    @else if $media == medium {

      @media only screen and (max-width: $mediumWidth ) {

        @content;
      }
    }

    @else if $media == large {

      @media only screen and (max-width: $largeWidth) {

        @content;
      }
    }
  }
}

@mixin img($img) {
  background-image: url("../assets/img/"+ $img );
  background-repeat: no-repeat;
}

@mixin icon($icon) {
  display: inline-block;

  @extend .icon-#{$icon};
  @extend .icon-#{$icon}-dims;
}

@mixin ribbon() {
  content: " ";
  width: 4px;
  height: 100%;
  background-color: $red-light;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 0 4px 4px 0;
}