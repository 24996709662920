.main-header {
  .header-heading {
    font-weight: 700;
    font-size: 3.125rem;
    margin: 2rem 0 0;

    @include media($mediumWidth) {
      font-size: 2.5rem;
      max-width: 90%;
      margin: 1.5rem auto 0;
    }

    @include media($smallWidth) {
      font-size: 1.75rem;
    }
  }

  .header-descr {
    padding: 1rem 0 2rem;

    p {
      color: #c3c3c5;
    }
  }

  @include media($mediumWidth) {
    text-align: center;
  }

  @include media($smallWidth) {
    #id_current_user_auth_subcontainer {
      display: inline-block;
      margin-left: 0;
    }

    .top-block-container #id_esauth_myaccount_login_link {
      padding: 11px 10px;
      margin: 0;
    }
  }
}


.main-top-container {
  padding-top: 85px;
  position: relative;
  z-index: 1;

  &:before {
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    bottom: 5px;
    right: -90px;
    left: 0;
    margin: auto;
    transform: translateX(100%);
    z-index: -2;

    @extend .icon-pen;
    @extend .icon-pen-dims;

    @include media($largeWidth) {
      display: none;
    }
  }

  &:after {
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    bottom: 0;
    height: 140px;
    width: 100%;
    z-index: -1;

    @include img("home-header.png");
  }

  @include media($largeWidth) {
    .header-heading,
    .header-descr {
      text-align: center;
    }
  }
}

.main-top-block {
  max-width: 70%;

  @include media($largeWidth) {
    max-width: 100%;
  }

  > div {
    max-width: calc(50% - 2rem);
    flex-basis: calc(50% - 2rem);
    width: 100%;
    margin-bottom: 2.125rem;

    @include media($largeWidth) {
      margin-bottom: 0;
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  .btn-block {
    max-width: 70%;

    @include media($mediumWidth) {
      max-width: 100%;
    }
  }
}

.section-order-banner {
  position: relative;
  overflow: hidden;
  z-index: 0;

  @extend .bg-color;

  .container {
    padding-top: 4rem;
    padding-bottom: 4rem;

    @include media($largeWidth) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}

.section-cta {
  .container {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.section-forms .content-heading {
  padding-bottom: 2rem;
}

.cta,
.guarantees-block,
.bfw {
  max-width: 765px;
  width: 66%;
  margin: auto;

  @include media($largeWidth) {
    max-width: 100%;
    width: 100%;
  }
}

.guarantees-block {
  max-width: 640px;
}

.cta {
  position: relative;

  &:before,
  &:after {
    position: absolute;
    content: "";
    margin: auto;
    width: 100%;
    height: 2rem;

    @extend .bg-orange;
  }

  &:before {
    top: -1.75rem;
    border-radius: 2px 2px 0 0;
  }

  &:after {
    bottom: -1.75rem;
    border-radius: 0 0 2px 2px;
  }

  .cta-title,
  .cta-description {
    font-size: 1.75em;

    @include media($largeWidth) {
      font-size: 1.25em;
    }
  }

  .cta-description {
    font-weight: 600;
    padding-top: .5em;
    padding-bottom: .75em;
  }

  .btn-block {
    padding-bottom: 0;
  }
}

.guarantees .content-heading {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.guarantees-menu {
  li {
    position: relative;
    list-style: none;
    font-weight: 400;
    font-size: 1.125rem;
    display: inline-block;
    width: 100%;
    color: $text-color;
    margin-bottom: 1em;

    &:before {
      width: 24px;
      height: 24px;
      display: inline-block;
      margin-right: .5rem;
      top: 0;
      left: 0;
      content: "\2714";
      color: $green;
      text-align: center;
      font-size: .85em;
      position: relative;
      vertical-align: middle;
      border-radius: 50%;
      border: 2px solid $green;
    }
  }
}

.home-features {
  margin-top: 2rem;

  .feature {
    max-width: calc(50% - (4.25rem / 3));
    flex-basis: calc(50% - (4.25rem / 3));
    width: 100%;
    margin-bottom: 3.75rem;
    border-radius: 10px;

    div {
      float: left;
    }

    h3 {
      font-weight: 600;
      color: $sidebar-title-color;
      font-size: 1.25em;
      margin-left: 105px;
    }

    p {
      margin: 0;
      color: $text-color;
      font-size: 1.25em;
      margin-left: 110px;
    }

    @include media(560px) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}


.section-price-calc {
  background-color: #f6f6f6;
  border-top: 1px solid #ddd;
  padding: 2.5rem 1rem;

  @include media($mediumWidth) {
    padding: 0;
  }
}

.stats-container {
  .ui-siteStats {
    display: flex;
    flex-direction: column;

    .ui-statItem {
      text-align: left;
      position: relative;
      padding-left: 70px;

      @include media($largeWidth) {
        text-align: center;
        width: 200px;
        margin: auto;
      }

      &:before {
        content: " ";
        background-repeat: no-repeat;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
      }

      &:nth-of-type(1):before {

        @extend .icon-idea;
        @extend .icon-idea-dims;
      }

      &:nth-of-type(2):before {

        @extend .icon-stars;
        @extend .icon-stars-dims;
      }

      &:nth-of-type(3):before {

        @extend .icon-writers;
        @extend .icon-writers-dims;
      }

      .ui-statItem-text {
        font-size: 1.15rem;
        font-weight: 400;
        color: $yellow;
        text-transform: lowercase;
      }
    }

    @include media($mediumWidth) {
      flex-wrap: wrap;

      .ui-statItem {
        width: 50%;
      }
    }

    @include media($smallWidth) {
      .ui-statItem {
        width: 100%;
      }
    }
  }
}

.ui-statItem-value {
  padding-bottom: 0;
  color: $yellow;

  b {
    font-weight: 400;
    color: $yellow;
  }

  span {
    span {
      display: none;
    }
  }

  @include media($mediumWidth) {
    font-size: 1.25rem;
  }
}

@include media($smallWidth) {
  #id_current_user_auth_subcontainer {
    display: inline-block;
    margin-left: 15px;
  }
}

.features-title {
  font-size: 1.65rem;
  color: #000;
  line-height: 1.4;
  font-weight: 600;
  margin: 1rem 0;
}

h1.features-title {
  font-size: 1.65rem !important;
  color: #000 !important;
  margin: 1rem 0 1.6rem;
}

.from-scratch-features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 60px;
  width: 100%;

  .feature-item {
    width: 28%;
    text-align: center;
    position: relative;

    @include media($mediumWidth) {
      width: 100%;
      margin-bottom: 30px;
    }

    &:before {
      content: "";
      margin-left: auto;
      margin-right: auto;
      display: block;
      background-position: center top;
      height: 88px;
      width: 88px;
      margin-bottom: 30px;
    }

    &:nth-child(1) {
      &:before {

        @include img("icon-eye.svg");
      }
    }

    &:nth-child(2) {
      &:before {

        @include img("icon-improve.svg");
      }
    }

    &:nth-child(3) {
      &:before {

        @include img("icon-time.svg");
      }
    }

    div,
    h3 {
      font-size: 18px;
      color: #8493a2;
      font-weight: 600;
      margin: 1rem 0;
    }

    p {
      font-size: 18px;
      color: #8493a2;
      line-height: 1.5;
    }
  }
}

