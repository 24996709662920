.sidebar {
  .sidebar-title,
  .widget-title,
  .kc__title {
    font-size: 1.25em;
    color: $sidebar-title-color;
    font-weight: 600;
  }

  .sidebar-menu,
  .widget,
  .contents {
    color: $sidebar-title-color;

    span {
      font-size: .875em;
    }
  }

  ul {
    ul {
      font-size: .875em;
    }
  }

  .sub_2,
  .sub_4 {
    margin-left: 20px;
    list-style-type: circle;
  }
}