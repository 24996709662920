%svg-common {
	background: url("../dist/img/sprite.svg") no-repeat;
}

.icon-curriculum {
	@extend %svg-common;
	background-position: 69.7211155378486% 42.64452380952381%;
}

.icon-curriculum-dims {
	width: 80px;
	height: 80px;
}

.icon-facebook {
	@extend %svg-common;
	background-position: 85.8695652173913% 11.299361702127658%;
}

.icon-facebook-dims {
	width: 30px;
	height: 30px;
}

.icon-google {
	@extend %svg-common;
	background-position: 91.30434782608695% 11.299361702127658%;
}

.icon-google-dims {
	width: 30px;
	height: 30px;
}

.icon-idea {
	@extend %svg-common;
	background-position: 94.79553903345725% 56.82171052631578%;
}

.icon-idea-dims {
	width: 44px;
	height: 44px;
}

.icon-intelligence {
	@extend %svg-common;
	background-position: 88.84462151394422% 19.787380952380953%;
}

.icon-intelligence-dims {
	width: 80px;
	height: 80px;
}

.icon-logo {
	@extend %svg-common;
	background-position: 100% 0;
}

.icon-logo-dims {
	width: 232px;
	height: 53.107px;
}

.icon-networking {
	@extend %svg-common;
	background-position: 85.65737051792829% 42.64452380952381%;
}

.icon-networking-dims {
	width: 80px;
	height: 80px;
}

.icon-paypal {
	@extend %svg-common;
	background-position: 76.41921397379913% 11.299361702127658%;
}

.icon-paypal-dims {
	width: 124px;
	height: 30px;
}

.icon-pen {
	@extend %svg-common;
	background-position: 0 0;
}

.icon-pen-dims {
	width: 350px;
	height: 500px;
}

.icon-safety-box {
	@extend %svg-common;
	background-position: 69.7211155378486% 61.69214285714285%;
}

.icon-safety-box-dims {
	width: 80px;
	height: 80px;
}

.icon-salary {
	@extend %svg-common;
	background-position: 85.65737051792829% 61.69214285714285%;
}

.icon-salary-dims {
	width: 80px;
	height: 80px;
}

.icon-settings {
	@extend %svg-common;
	background-position: 72.01646090534979% 20.571039603960397%;
}

.icon-settings-dims {
	width: 96px;
	height: 96px;
}

.icon-stars {
	@extend %svg-common;
	background-position: 99.62121212121212% 18.225219298245616%;
}

.icon-stars-dims {
	width: 54px;
	height: 44px;
}

.icon-writers {
	@extend %svg-common;
	background-position: 94.79553903345725% 40.158520179372196%;
}

.icon-writers-dims {
	width: 44px;
	height: 54px;
}

